import { Box, Container } from '@chakra-ui/react'
import { Script } from 'gatsby'

const GiftGuide = () => {
  return (
    <Box>
      <Container
        maxW="container.full"
        minH="0!important"
        py={0}
        px={0}
        paddingBottom="0!important"
        id="experience-653c01a14b3df"
        data-aspectratio="0.13391923"
        data-mobile-aspectratio="0.03857143"
      >
        <iframe
          className={'ceros-experience'}
          src="https://view.ceros.com/hello-sunshine/gift-guide-rbc"
          title="Gift Guide RBC"
          seamless={true}
          allowFullScreen
          width={'100%'}
          height={'1024'}
        ></iframe>
      </Container>
      <Script
        src={'https://view.ceros.com/scroll-proxy.min.js'}
        data-ceros-origin-domains="view.ceros.com"
      />
    </Box>
  )
}

export default GiftGuide
